import * as React from "react";
import { graphql } from "gatsby";
import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Copyright from '../components/Footer';
import LanguageIcon from '@mui/icons-material/Language';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContentCut from '@mui/icons-material/ContentCut';
import SwipeableTemporaryDrawer from '../components/SwipeableTemporaryDrawer';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#017FFE',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

export default function Index() {
  const { t } = useTranslation("translations");
  const { languages, originalPath, language} = useI18next();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <AppBar position="sticky" style={{ background: 'transparent', boxShadow: 'none' }}>
          <Toolbar style={{ background: 'transparent', boxShadow: 'none', paddingLeft: 0, paddingRight: 0 }} sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: "space-between" }}>
            <img width="167px" src="/logo.svg" />
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              align="center"
              noWrap
              sx={{ flex: 0, marginTop: 0 }}
            >

            </Typography>

            <div>
                  <SwipeableTemporaryDrawer />
                </div>

          </Toolbar>
        </AppBar>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Gatsby example
          </Typography>
          <h1>{t("hello")}</h1>
          <Typography variant="h4" component="h1" gutterBottom>
            <Trans>
              hello
            </Trans>
          </Typography>
          <b>TEST: {language}</b>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Link to="/about" color="secondary">
            Go to the about page
          </Link>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
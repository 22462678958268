import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import US from 'country-flag-icons/react/3x2/US';
import IT from 'country-flag-icons/react/3x2/IT';
import DE from 'country-flag-icons/react/3x2/DE';
import FR from 'country-flag-icons/react/3x2/FR';
import SvgIcon from '@mui/material/SvgIcon';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const { languages, originalPath, language } = useI18next();

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

      <Divider />
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Language
          </ListSubheader>
        }
      >
        <Link to={originalPath} language="en" style={{ textDecoration: 'none', display: 'block', color: '#000000' }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SvgIcon>
                  <US title="United States" className="..." />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="English" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to={originalPath} language="it" style={{ textDecoration: 'none', display: 'block', color: '#000000' }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SvgIcon>
                  <IT title="Italy" className="..." />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Italiano" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to={originalPath} language="de" style={{ textDecoration: 'none', display: 'block', color: '#000000' }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SvgIcon>
                  <DE title="Germany" className="..." />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Deutsch" />
            </ListItemButton>
          </ListItem>
        </Link>

        <Link to={originalPath} language="fr" style={{ textDecoration: 'none', display: 'block', color: '#000000' }}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SvgIcon>
                  <FR title="France" className="..." />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Français" />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <IconButton onClick={toggleDrawer("right", true)}>
          <LanguageIcon fontSize="large" sx={{ color: 'primary.main' }} />
        </IconButton>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
